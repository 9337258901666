<template>
  <div>
    <div class="row justify-center" v-if="acceptance">
      <h9 class="q-my-none q-mr-sm q-mb-sm">
        {{ $t('Purchase order') }}
      </h9>

      <div>
        <q-badge
            :label="this.acceptance"
            color="green"
            class="text-h9"
        />
      </div>
    </div>

    <div v-if="!place && !item">
      <div
          class="text-center q-pa-sm"
          style="height: 190px;"
      >
        <img
            src="assets/terminal-icons-md/IMG-0942.png"
            style="width: auto; height: 100%; object-fit: contain;"
        >
      </div>

      <div class="row justify-center">
        <h6 class="q-my-none q-mr-sm">
          {{ $t('Please scan') }}
        </h6>

        <div>
          <q-badge
              :label="$t('Location TO')"
              color="amber"
              class="text-h6"
          />

          <p class="q-my-sm text-caption text-center text-weight-bold text-grey-7">
            {{ $t('Except BASKET') }}
          </p>
        </div>
      </div>

    </div>

    <div v-if="place && !item">
      <div
          class="text-center q-pa-sm"
          style="height: 190px;"
      >
        <img
            src="assets/terminal-icons-md/IMG-0943.png"
            style="width: auto; height: 100%; object-fit: contain;"
        >
      </div>

      <div class="row items-center justify-center">
        <h6 class="q-my-none q-mr-sm">
          {{ $t('Please scan') }}
        </h6>

        <q-badge
            :label="$t('Items')"
            color="amber"
            class="text-h6"
        />
      </div>
    </div>

    <div
        v-if="item"
        :key="`${item.id}:${item.sku}`"
    >
      <product-section :data="item"/>

      <quantity-section
          :data="quantity"
          :options="quantityOptions"
          @click="handleEvent"
      />

      <counter-section :data="counter" @click="handleEvent"/>
    </div>

    <div class="border-top q-pa-xs q-mt-sm border-top--bold">
      <base-place-section :data="place" :options="options"/>
    </div>

    <offers-modal ref="offersModal"/>

    <portal v-if="item" to="additional-tiles">
      <div
          class="text-center rounded row items-center justify-center"
          style="background: rgba(0, 0, 0, 0.9); min-height: 66px;"
          @click="handleFinish"
      >
        <div class="q-px-sm">
          <q-icon name="check" size="2rem"/>

          <div class="text-caption">
            {{ $t('Done') }}
          </div>
        </div>
      </div>
    </portal>

    <error-screen
        :message="error"
        @reset="handleErrorClose"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import OffersModal from '../../components/modals/OffersModal.vue'

export default {
  name: 'Allocate',
  components: {
    OffersModal
  },
  props: {
    barcode: {
      type: [Object, null],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      place: null,
      acceptance: null,
      item: null,
      items: [],
      allocatedItems: [],
      amount: 1,
      error: ''
    }
  },
  computed: {
    ...mapGetters([
      'findStoragePlaceEvent'
    ]),
    quantityOptions () {
      return {
        maxDescription: 'tap to add all',
        max: 'Available',
        current: 'On the location'
      }
    },
    quantity () {
      return {
        max: this.item.new,
        current: this.item.count
      }
    },
    counter () {
      return {
        max: this.item.new
      }
    },
    options () {
      return {
        label: this.$t('TO'),
        defaultColor: 'amber',
        selectedColor: 'positive'
      }
    }
  },
  watch: {
    barcode (value) {
      this.handleBarcode(value)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleFinish () {
      this.$emit('finish')
    },
    handleErrorClose () {
      this.error = ''
    },
    handleEvent (value) {
      if (!value.event) {
        return
      }

      const events = {
        'counter.submit': (data) => {
          this.amount = Number(data.value || 0)
          return this.handleBarcode(this.barcode)
        },
        'counter.focus': () => {
          this.$emit('focus', true)
        },
        'counter.focusOut': () => {
          this.$emit('focusOut', true)
        }
      }

      if (typeof events[value.event] === 'function') {
        return events[value.event](value.data)
      }

      console.error(`Event is not recognized! ${value.event}`)
    },
    getPlaceError (barcode) {
      if (this.place && `${this.place.id}` === `${barcode.value}`) {
        return `Place with barcode ${barcode.raw} is already scanned`
      }

      if (this.findStoragePlaceEvent(barcode.value)) {
        return 'Place is already used from someone!'
      }

      return ''
    },
    getItemError () {
      if (!this.place) {
        return 'You must scan place!'
      }

      if (this.place.type === 'employee') {
        return 'You can\'t allocate products on employee place!'
      }

      return ''
    },
    getError (barcode) {
      return barcode.raw.includes('S/P/')
          ? this.getPlaceError(barcode)
          : this.getItemError(barcode)
    },
    scanPlace (barcode) {
      return this.$service.storagePlace.get(barcode.value)
          .then(place => {
            this.place = place
            this.item = null
            this.items = []
            this.$emit('item', null)
            return place
          })
    },
    getItemByBarcode (arr, barcode) {
      const items = arr.filter(({ sku }) => sku === barcode.raw)

      if (items.length > 1) {
        return this.$refs.offersModal.show(items)
      }

      return Promise.resolve(items[0] || null)
    },
    async getAllocatedItem (barcode) {
      const item = await this.getItemByBarcode(this.allocatedItems, barcode)

      if (item) {
        return Promise.resolve(item)
      }

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          {
            type: 'orx',
            conditions: [
              { field: 'state', type: 'eq', value: 'new' },
              { field: 'state', type: 'eq', value: 'blocked' },
              {
                type: 'andx',
                conditions: [
                  { field: 'state', type: 'eq', value: 'booked' },
                  { field: 'reserve', type: 'isNull' }
                ],
                where: 'and'
              }
            ],
            where: 'and'
          },
          { type: 'isnull', field: 'place' }
        ],
        group: [
          { field: 'place', alias: 'i' }
        ]
      }

      query.filter.push({ type: 'eq', field: 'sku', value: barcode.raw })

      if (this.acceptance) {
        query.filter.push({ type: 'eq', field: 'createdByDocument', value: this.acceptance })
      }

      return this.$service.storageItemEntity.getAll(query, this.place.id)
          .then(data => {
            return {
              ...data,
              items: this.$utils.formatGroupedItemsByOffer(data.items)
            }
          })
          .then(({ items }) => {
            if (items.length > 1) {
              return this.$refs.offersModal.show(items)
            }

            return items[0]
          })
          .then(item => {
            if (!item) {
              return Promise.reject(new Error(`Product with barcode ${barcode.raw} is not found or quantity is not enough!`))
            }

            const updatedItem = {
              ...item,
              sku: barcode.raw,
              new: item.count
            }

            this.allocatedItems.push(updatedItem)

            return updatedItem
          })
    },
    async loadItem (offerId, barcode) {
      const item = await this.getItemByBarcode(this.items, barcode)

      if (item) {
        return Promise.resolve(item)
      }

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'place', value: this.place.id },
          { type: 'eq', field: 'productOffer', value: offerId },
          { type: 'eq', field: 'sku', value: barcode.raw }
        ],
        group: [
          { field: 'place', alias: 'i' },
          { field: 'productOffer', alias: 'i' }
        ]
      }

      return this.$service.storageItemEntity.getAll(query, this.place.id)
          .then(data => {
            return {
              ...data,
              items: this.$utils.formatGroupedItemsByOffer(data.items)
            }
          })
          .then(({ items }) => {
            const item = items[0]

            if (!item) {
              return null
            }

            const updatedItem = {
              ...item,
              sku: barcode.raw
            }

            this.items.push(updatedItem)

            return updatedItem
          })
    },
    scanItem (barcode) {
      let newItem = null

      return this.getAllocatedItem(barcode)
          .then(item => {
            if (item.new < this.amount) {
              return Promise.reject(new Error(`Max quantity is ${item.new}`))
            }

            newItem = item

            return this.loadItem(item.id, barcode)
          })
          .then(() => {
            let query = {
              per_page: this.amount,
              filter: [
                { type: 'eq', field: 'sku', value: barcode.raw },
                {
                  type: 'orx',
                  conditions: [
                    { field: 'state', type: 'eq', value: 'new' },
                    { field: 'state', type: 'eq', value: 'blocked' },
                    {
                      type: 'andx',
                      conditions: [
                        { field: 'state', type: 'eq', value: 'booked' },
                        { field: 'reserve', type: 'isNull' }
                      ],
                      where: 'and'
                    }
                  ],
                  where: 'and'
                },
                { type: 'isnull', field: 'place' }
              ]
            }

            if (newItem.id) {
              query.filter.push({ type: 'eq', field: 'productOffer', value: newItem.id })
            } else {
              query.filter.push({ type: 'isnull', field: 'productOffer' })
            }

            if (this.acceptance) {
              query.filter.push({ type: 'eq', field: 'createdByDocument', value: this.acceptance })
            }

            const data = [{
              place: this.place.id,
              state: 'normal'
            }]

            return this.$service.storageItemEntity.patch(data, query, 'application/json+offers', this.place.id)
          })
          .then(() => {
            let isExist = false

            this.items = this.items.map(i => {
              if (i.id === newItem.id) {
                this.item = { ...i, count: i.count + this.amount, new: newItem.new - this.amount }
                this.$emit('item', this.item)
                isExist = true
                return this.item
              }

              return i
            })

            if (!isExist) {
              this.item = {
                ...newItem,
                new: newItem.new - this.amount,
                count: this.amount
              }
              this.$emit('item', this.item)
              this.items.push(this.item)
            }

            this.allocatedItems = this.allocatedItems.reduce((acc, i) => {
              if (i.id !== newItem.id) {
                acc.push(i)
              } else {
                const updatedItem = { ...i, new: newItem.new - this.amount }

                if (updatedItem.new > 0) {
                  acc.push(updatedItem)
                }
              }

              return acc
            }, [])

            this.amount = 1

            return this.items
          })
    },
    handleBarcode (barcode) {
      if (barcode.raw.includes('P/O/') || barcode.raw.includes('S/A')) {
        this.acceptance = barcode.value

        return
      }

      const error = this.getError(barcode)

      if (error) {
        return this.addErrorNotification(error)
      }

      return Promise.resolve(barcode.raw.includes('S/P/'))
          .then(isPlace => {
            return isPlace
                ? this.scanPlace(barcode)
                : this.scanItem(barcode)
          })
          .catch(error => {
            this.error = error.message
          })
    }
  }
}
</script>
