<template>
  <div class="q-pa-xs">
    <portal to="header">
      <terminal-header
        :title="$t('Transfer')"
        :out-focused="isFocused"
        @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <q-carousel
      :model-value="slide"
      transition-prev="scale"
      transition-next="scale"
      swipeable
      animated
      class="terminal-min-height"
    >
      <q-carousel-slide
        name="options"
        class="q-pa-none terminal-min-height"
      >
        <jumbotron>
          <div
            style="min-width: 290px;"
            class="border rounded q-pa-sm"
          >
            <div class="text-center text-subtitle2 q-mb-sm">
              {{ $t('What needs to be done?') }}
            </div>

            <div class="row items-center">
              <q-btn
                :label="$t('Transfer')"
                color="teal"
                no-caps
                @click="setSlide('moveItems')"
              />

              <q-space />

              <q-btn
                :label="$t('Allocation')"
                color="warning"
                no-caps
                @click="setSlide('allocate')"
              />
            </div>
          </div>
        </jumbotron>
      </q-carousel-slide>

      <q-carousel-slide name="moveItems" class="q-pa-none terminal-min-height">
        <move-items
          :barcode="barcode"
          @finish="handleFinish"
          @focus="isFocused = true"
          @focus-out="isFocused = false"
        />
      </q-carousel-slide>

      <q-carousel-slide name="allocate" class="q-pa-none terminal-min-height">
        <allocate
          :barcode="barcode"
          @finish="handleFinish"
          @item="handleItem"
          @focus="isFocused = true"
          @focus-out="isFocused = false"
        />
      </q-carousel-slide>

      <q-carousel-slide name="finish" class="q-pa-none row items-center justify-center terminal-min-height">
        <div>
          <div
            class="text-center q-pa-sm"
            style="height: 190px;"
          >
            <img
              src="assets/terminal-icons-md/IMG-0951.png"
              style="width: auto; height: 100%; object-fit: contain;"
            >
          </div>

          <h6 class="q-my-none text-center">
            {{ $t(finishText) }}
          </h6>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>

  <location-logs ref="logs" />
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import Allocate from './Allocate.vue'
import MoveItems from './MoveItems.vue'

// Utils
import _ from 'lodash'

export default {
  name: 'Transfer',
  components: {
    MoveItems,
    Allocate
  },
  data () {
    return {
      barcode: null,
      isFocused: false,
      slide: 'options',
      timeout: null,
      item: null,
      messages: [
        'You did it!',
        'Great job!',
        'Well done!'
      ],
      finishText: ''
    }
  },
  computed: {
    tilesSchema () {
      const options = []

      if (this.slide !== 'options') {
        options.push({
          id: 1,
          label: this.$t('Start new cycle'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.setSlide('options')
          }
        })
        if (this.item && this.slide === 'allocate') {
          options.push({
            id: 2,
            label: this.$t('Locations'),
            icon: 'history',
            value: true,
            onChanged: () => {
              this.$refs.logs.open(this.item)
            }
          })
        }
      }

      return options
    }
  },
  unmounted () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleItem (item) {
      this.item = item
    },
    setSlide (slide) {
      this.item = null
      this.slide = slide
    },
    handleFinish () {
      this.item = null
      this.slide = 'finish'
      this.finishText = _.sample(this.messages)

      this.timeout = setTimeout(() => {
        this.slide = 'options'
      }, 3000)
    },
    handleBarcode (barcode) {
      if (this.slide === 'options') {
        return this.addErrorNotification('Please select option')
      }

      this.barcode = barcode
    }
  }
}
</script>
